import {
    Button,
    Flex,
    Group,
    Modal,
    Stack,
    Text,
    Title,
    type ModalProps,
} from '@mantine/core';
import { IconFolders } from '@tabler/icons-react';
import React, { type FC } from 'react';
import { useMoveChartMutation } from '../../../hooks/useSavedQuery';
import MantineIcon from '../MantineIcon';

interface Props extends ModalProps {
    uuid: string;
    name: string;
    spaceUuid: string;
    spaceName: string;
    onConfirm: () => void;
}

const MoveChartThatBelongsToDashboardModal: FC<Props> = ({
    uuid,
    name,
    spaceUuid,
    spaceName,
    onConfirm,
    ...modalProps
}) => {
    const { mutate: moveChartToSpace } = useMoveChartMutation({
        onSuccess: async () => {
            onConfirm();
            modalProps.onClose();
        },
    });

    return (
        <Modal
            size="lg"
            title={
                <Flex align="center" gap="xs">
                    <MantineIcon icon={IconFolders} size="lg" />
                    <Title order={5}>
                        <Text span fw={400}>
                            移动{' '}
                        </Text>
                        {name}
                    </Title>
                </Flex>
            }
            {...modalProps}
        >
            <Stack mt="sm">
                <Text>
                    确定要移动图表{' '}
                    <Text fw={600} span>
                        {name}
                    </Text>{' '}
                    至空间{' '}
                    <Text fw={600} span>
                        {spaceName}
                    </Text>
                    吗?
                </Text>
                <Text>
                    此图表是在仪表盘内创建的，将
                    图表移动到空间将使其在整个应用程序的
                    图表列表中可用。
                </Text>
                <Text fw={600}>此操作不可逆.</Text>

                <Group position="right" spacing="xs">
                    <Button variant="outline" onClick={modalProps.onClose}>
                    取消
                    </Button>

                    <Button
                        onClick={() => {
                            moveChartToSpace({
                                uuid,
                                spaceUuid,
                            });
                        }}
                        type="submit"
                    >
                        移动
                    </Button>
                </Group>
            </Stack>
        </Modal>
    );
};

export default MoveChartThatBelongsToDashboardModal;
