import {
    LightdashRequestMethodHeader,
    RequestMethod,
    type ApiError,
    type ApiResponse,
} from '@lightdash/common';
import * as Sentry from '@sentry/react';
import fetch from 'isomorphic-fetch';
import CryptoJS from 'crypto-js';

export const BASE_API_URL =
    import.meta.env.VITEST === 'true'
        ? `http://test.lightdash/`
        : import.meta.env.BASE_URL;

const defaultHeaders = {
    'Content-Type': 'application/json',
    [LightdashRequestMethodHeader]: RequestMethod.WEB_APP,
};


const handleError = (err: any): ApiError => {
    if (err.error?.statusCode && err.error?.name) return err;
    return {
        status: 'error',
        error: {
            name: 'NetworkError',
            statusCode: 500,
            message:
                'We are currently unable to reach the Lightdash server. Please try again in a few moments.',
            data: err,
        },
    };
};

type LightdashApiProps = {
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
    url: string;
    body: BodyInit | null | undefined;
    headers?: Record<string, string> | undefined;
    version?: 'v1' | 'v2';
};

let rawToken: string | any; // 声明一个全局变量来存储 token

// 监听来自父窗口的消息事件
window.addEventListener('message', (event) => {
    // 获取 token 信息并存储在全局变量中
    const tokenData = event.data;
    rawToken = tokenData.token;
    console.log("rawToken =", rawToken) 
    if ( rawToken != null){
        const secret = 'xmo1234'; // 请替换为你的签名密钥
        const signedValue = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(rawToken, secret));
        const signedToken = `${rawToken}.${signedValue}`;
        document.cookie= `connect.sid=s%3A${signedToken.replace(/=+$/, '')}; path=/;`;
        console.log("cookie", document.cookie)
    }    
});

export const lightdashApi = async <T extends ApiResponse['results']>({
    method,
    url,
    body,
    headers,
    version = 'v1',
}: LightdashApiProps): Promise<T> => {
    
    const apiPrefix = `${BASE_API_URL}api/${version}`;
    if(rawToken == null){
        await new Promise(resolve => setTimeout(resolve, 1500));
    }
    let sentryTrace: string | undefined;
    // Manually create a span for the fetch request to be able to trace it in Sentry. This also enables Distributed Tracing.
    Sentry.startSpan(
        {
            op: 'http.client',
            name: `API Request: ${method} ${url}`,
            attributes: {
                'http.method': method,
                'http.url': url,
                type: 'fetch',
                url,
                method,
            },
        },
        (s) => {
            sentryTrace = Sentry.spanToTraceHeader(s);
        },
    );

    return fetch(`${apiPrefix}${url}`, {
        method,
        headers: {
            ...defaultHeaders,
            ...headers,
            ...(sentryTrace ? { 'sentry-trace': sentryTrace } : {})
        },
        body,
    })
        .then((r) => {
            if (!r.ok) {
                return r.json().then((d) => {
                    throw d;
                });
            }
            return r;
        })
        .then((r) => r.json())
        .then((d: ApiResponse | ApiError) => {
            switch (d.status) {
                case 'ok':
                    // make sure we return null instead of undefined
                    // otherwise react-query will crash
                    return (d.results ?? null) as T;
                case 'error':
                    throw d;
                default:
                    throw d;
            }
        })
        .catch((err) => {
            throw handleError(err);
        });
};
