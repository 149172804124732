import { createStyles, keyframes, Loader, Text } from '@mantine/core';
import { type FC } from 'react';
import { TrackSection } from '../../../providers/TrackingProvider';
import NoTableIcon from '../../../svgs/emptystate-no-table.svg?react';
import { SectionName } from '../../../types/Events';
import { EmptyState } from '../../common/EmptyState';
import DocumentationHelpButton from '../../DocumentationHelpButton';
import { RefreshButton } from '../../RefreshButton';

const animationKeyframes = keyframes`
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
        transform: translateY(-10px);
    }
    10% {
        opacity: 1;
        transform: translateY(0px);
    }
    25% {
        opacity: 1;
        transform: translateY(0px);
    }
    30% {
        opacity: 0;
        transform: translateY(10px);
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`;

const useAnimatedTextStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
        height: theme.spacing.lg,
        textAlign: 'center',
        width: '100%',

        '& > span': {
            animation: `${animationKeyframes} 16s linear infinite 0s`,
            opacity: 0,
            overflow: 'hidden',
            position: 'absolute',
            width: '100%',
            left: 0,
        },

        '& span:nth-of-type(2)': {
            animationDelay: '4s',
        },

        '& span:nth-of-type(3)': {
            animationDelay: '8s',
        },

        '& span:nth-of-type(4)': {
            animationDelay: '12s',
        },
    },
}));

const ExploreDocumentationUrl =
    'https://docs.lightdash.com/get-started/exploring-data/using-explores/';

export const EmptyStateNoColumns = () => {
    const { classes } = useAnimatedTextStyles();

    return (
        <EmptyState
            title={
                <>
                    选择一个指标并选择其维度{' '}
                    {/* <DocumentationHelpButton
                        href={ExploreDocumentationUrl}
                        pos="relative"
                        top={2}
                        iconProps={{ size: 'lg' }}
                    /> */}
                </>
            }
            description={
                <>
                    您的数据问题是什么？选择您要计算的
                    <Text span color="yellow.9">
                        指标
                    </Text>
                    以及想要划分的
                    <Text span color="blue.9">
                        维度
                    </Text>
                </>
            }
        >
            <Text className={classes.root} color="dimmed">
                <Text span>
                    例如. 
                    <Text span color="blue.9">
                        每天
                    </Text>
                    有
                    <Text span color="yellow.9">
                        多少人
                    </Text>
                    注册
                    ?
                </Text>

                <Text span>
                    例如. 按
                    <Text span color="blue.9">
                    地点
                    </Text>
                    划分的
                    <Text span color="yellow.9">
                        订单总数
                    </Text>
                    是多少
                    ?
                </Text>

                <Text span>
                    例如. 
                    <Text span color="blue.9">每周</Text>
                    有多少位
                    <Text span color="yellow.9">新关注者</Text>
                    ?
                </Text>

                <Text span>
                    例如. 按
                    <Text span color="blue.9">状态</Text>
                    划分的
                    <Text span color="yellow.9">订单总数</Text>
                    是多少
                    ?
                </Text>
            </Text>
        </EmptyState>
    );
};

export const EmptyStateNoTableData: FC<{ description: React.ReactNode }> = ({
    description,
}) => (
    <TrackSection name={SectionName.EMPTY_RESULTS_TABLE}>
        <EmptyState
            maw={500}
            description={
                <>
                    {description}{' '}
                    {/* <DocumentationHelpButton
                        href={ExploreDocumentationUrl}
                        pos="relative"
                        top={2}
                    /> */}
                </>
            }
        >
            <RefreshButton size={'xs'} />
        </EmptyState>
    </TrackSection>
);

export const NoTableSelected = () => (
    <EmptyState
        maw={500}
        icon={<NoTableIcon />}
        title="选择一张表"
        description={
            <>
                要运行查询，首先选择您想要探索的表{' '}
                {/* <DocumentationHelpButton
                    href={ExploreDocumentationUrl}
                    pos="relative"
                    top={2}
                /> */}
            </>
        }
    />
);

export const EmptyStateExploreLoading = () => (
    <EmptyState title="加载表格...">
        <Loader color="gray" />
    </EmptyState>
);

export const ExploreIdleState = () => (
    <EmptyState title="Run query to see your results" />
);

export const ExploreEmptyQueryState = () => (
    <EmptyState
        title="Query returned no results"
        description="This query ran successfully but returned no results"
    />
);

export const ExploreLoadingState = () => (
    <EmptyState title="加载中">
        <Loader color="gray" />
    </EmptyState>
);
