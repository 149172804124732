import { hasChartsInDashboard, isChartTile } from '@lightdash/common';
import {
    Button,
    Group,
    List,
    Modal,
    Stack,
    Text,
    Title,
    type ModalProps,
} from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { type FC } from 'react';
import {
    useDashboardDeleteMutation,
    useDashboardQuery,
} from '../../../hooks/dashboard/useDashboard';
import MantineIcon from '../MantineIcon';

interface DashboardDeleteModalProps extends ModalProps {
    uuid: string;
    onConfirm?: () => void;
}

const DashboardDeleteModal: FC<DashboardDeleteModalProps> = ({
    uuid,
    onConfirm,
    ...modalProps
}) => {
    const { data: dashboard, isInitialLoading } = useDashboardQuery(uuid);
    const { mutateAsync: deleteDashboard, isLoading: isDeleting } =
        useDashboardDeleteMutation();

    if (isInitialLoading || !dashboard) {
        return null;
    }

    const handleConfirm = async () => {
        await deleteDashboard(uuid);
        onConfirm?.();
    };

    const chartsInDashboardTiles = dashboard.tiles.filter(
        (tile) => isChartTile(tile) && tile.properties.belongsToDashboard,
    );

    return (
        <Modal
            title={
                <Group spacing="xs">
                    <MantineIcon icon={IconTrash} color="red" size="lg" />
                    <Title order={4}>删除仪表盘</Title>
                </Group>
            }
            {...modalProps}
        >
            <Stack>
                {hasChartsInDashboard(dashboard) ? (
                    <Stack>
                        <Text>
                            你确定要删除仪表盘{' '}
                            <b>"{dashboard.name}"</b>吗?
                        </Text>
                        <Text>
                            此操作还将永久删除其中创建的以下图表：
                        </Text>
                        <List size="sm">
                            {chartsInDashboardTiles.map(
                                (tile) =>
                                    isChartTile(tile) && (
                                        <List.Item key={tile.uuid}>
                                            <Text>
                                                {tile.properties.chartName}
                                            </Text>
                                        </List.Item>
                                    ),
                            )}
                        </List>
                    </Stack>
                ) : (
                    <Text>
                        您确定要删除仪表盘{' '}
                        <b>"{dashboard.name}"</b>吗?
                    </Text>
                )}

                <Group position="right" spacing="xs">
                    <Button
                        color="dark"
                        variant="outline"
                        onClick={modalProps.onClose}
                    >
                        取消
                    </Button>

                    <Button
                        color="red"
                        loading={isDeleting}
                        onClick={handleConfirm}
                        type="submit"
                    >
                        删除
                    </Button>
                </Group>
            </Stack>
        </Modal>
    );
};

export default DashboardDeleteModal;
