export interface AccessOption {
    title: string;
    description?: string;
    selectDescription: string;
    value: string;
}

export const enum SpacePrivateAccessType {
    PRIVATE = 'private',
    SHARED = 'shared',
}

export const enum SpaceAccessType {
    PRIVATE = 'private',
    PUBLIC = 'public',
}

export const SpaceAccessOptions: AccessOption[] = [
    {
        title: '限制访问',
        description: '只有受邀成员和管理员才能访问',
        selectDescription: '只有受邀成员和管理员才能访问',
        value: SpaceAccessType.PRIVATE,
    },
    {
        title: '公开访问',
        description: '所有项目成员可以访问',
        selectDescription:
            '所有项目成员均可使用其项目权限进行访问',
        value: SpaceAccessType.PUBLIC,
    },
];

export const enum UserAccessAction {
    DELETE = 'delete',
    VIEWER = 'viewer',
    EDITOR = 'editor',
    ADMIN = 'admin',
}

export const UserAccessOptions: AccessOption[] = [
    {
        title: 'Can view',
        selectDescription: `View space contents.`,
        value: UserAccessAction.VIEWER,
    },
    {
        title: 'Can edit',
        selectDescription: `Edit space contents.`,
        value: UserAccessAction.EDITOR,
    },
    {
        title: 'Full access',
        selectDescription: `Manage space access and content.`,
        value: UserAccessAction.ADMIN,
    },
    {
        title: 'No access',
        selectDescription: `Remove user's access`,
        value: UserAccessAction.DELETE,
    },
];
