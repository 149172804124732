import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-cn'; // 引入中文语言包
import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

dayjs.extend(relativeTime);
dayjs.locale('zh-cn'); // 设置 dayjs 的语言为中文

export const useTimeAgo = (timeStamp: Date, interval: number = 10000) => {
    const [timeAgo, setTimeAgo] = useState<string>(dayjs(timeStamp).fromNow());
    useInterval(() => {
        setTimeAgo(dayjs(timeStamp).fromNow());
    }, interval);
    useEffect(() => {
        setTimeAgo(dayjs(timeStamp).fromNow());
    }, [timeStamp]);
    return timeAgo;
};