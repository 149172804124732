import { ActionIcon, Box, Button, Group, Text } from '@mantine/core';
import { type FC } from 'react';
import { Link } from 'react-router-dom';
import Omnibar from '../../features/omnibar';
import Logo from '../../svgs/logo-icon.svg?react';
import BrowseMenu from './BrowseMenu';
import ExploreMenu from './ExploreMenu';
import HeadwayMenuItem from './HeadwayMenuItem';
import HelpMenu from './HelpMenu';
import { NotificationsMenu } from './NotificationsMenu';
import ProjectSwitcher from './ProjectSwitcher';
import SettingsMenu from './SettingsMenu';
import UserCredentialsSwitcher from './UserCredentialsSwitcher';
import UserMenu from './UserMenu';
import { useHistory } from 'react-router-dom';
import MantineIcon from '../common/MantineIcon';
import {
    IconSquarePlus,
    IconChartHistogram,
    IconDashboard
} from '@tabler/icons-react';

type Props = {
    activeProjectUuid: string | undefined;
    isLoadingActiveProject: boolean;
};

export const MainNavBarContent: FC<Props> = ({
    activeProjectUuid,
    isLoadingActiveProject,
}) => {
    const homeUrl = activeProjectUuid
        ? `/projects/${activeProjectUuid}/home`
        : '/';

    const history = useHistory();

    const goTables= (activeProjectUuid: string)=>{
        history.push(
            `/projects/${activeProjectUuid}/tables`,
        );
    }

    const goCharts = (activeProjectUuid: string)=>{
        history.push(
            `/projects/${activeProjectUuid}/saved`,
        );
    }

    const goDashboards = (activeProjectUuid: string)=>{
        history.push(
            `/projects/${activeProjectUuid}/dashboards`,
        );
    }

    return (
        <>
            <Group align="center" sx={{ flexShrink: 0 }}>
                {/* <ActionIcon
                    component={Link}
                    to={homeUrl}
                    title="Home"
                    size="lg"
                >
                    <Logo />
                </ActionIcon> */}
                <Text color="rgba(0, 0, 0, 0.85)" fw={400} size="lg">数据洞察</Text>
                {/* <div></div> */}

                {!isLoadingActiveProject && activeProjectUuid && (
                    <>
                        {/* <Button.Group> */}
                            {/* <ExploreMenu projectUuid={activeProjectUuid} /> */}
                            <Button
                                variant="default"
                                size="xs"
                                fz="sm"
                                onClick={()=>{goTables(activeProjectUuid)}}
                                leftIcon={<MantineIcon icon={IconSquarePlus} />}
                                // mr='xs'
                                sx={() => ({
                                    ':hover': {
                                        backgroundColor: '#448EF8',
                                        color: '#fff'
                                    },
                                })}
                            >
                                探索
                            </Button>

                            <Button
                                variant="default"
                                size="xs"
                                fz="sm"
                                onClick={()=>{goCharts(activeProjectUuid)}}
                                leftIcon={<MantineIcon icon={IconChartHistogram} />}
                                // mr='xs'
                                sx={() => ({
                                    ':hover': {
                                        backgroundColor: '#448EF8',
                                        color: '#fff',
                                    },
                                })}
                            >
                                图表
                            </Button>

                            <Button
                                variant="default"
                                size="xs"
                                fz="sm"
                                onClick={()=>{goDashboards(activeProjectUuid)}}
                                leftIcon={<MantineIcon icon={IconDashboard} />}
                                // mr='xs'
                                sx={() => ({
                                    ':hover': {
                                        backgroundColor: '#448EF8',
                                        color: '#fff'
                                    },
                                })}
                            >
                                仪表盘
                            </Button>

                           
                            {/* <BrowseMenu projectUuid={activeProjectUuid} /> */}
                        {/* </Button.Group> */}
                        {/* <Omnibar projectUuid={activeProjectUuid} /> */}
                    </>
                )}
            </Group>

            {/* <Box sx={{ flexGrow: 1 }} />

            <Group sx={{ flexShrink: 0 }}>
                <Button.Group>
                    <SettingsMenu />

                    {!isLoadingActiveProject && activeProjectUuid && (
                        <NotificationsMenu projectUuid={activeProjectUuid} />
                    )}

                    <HelpMenu />

                    {!isLoadingActiveProject && activeProjectUuid && (
                        <HeadwayMenuItem projectUuid={activeProjectUuid} />
                    )}

                    <ProjectSwitcher />
                </Button.Group>

                <UserCredentialsSwitcher />
                <UserMenu />
            </Group> */}
        </>
    );
};
